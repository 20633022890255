/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onRegisterCompleted = /* GraphQL */ `
  subscription OnRegisterCompleted($registrationId: String!) {
    onRegisterCompleted(registrationId: $registrationId) {
      registrationId
      magicLink
      error
    }
  }
`;
export const onRegisterToWebinarCompleted = /* GraphQL */ `
  subscription OnRegisterToWebinarCompleted($registrationId: String!) {
    onRegisterToWebinarCompleted(registrationId: $registrationId) {
      registrationId
      joinUrl
    }
  }
`;
