import React from 'react';
import { EventType } from '../LayoutWrapper';
import { PrivateMeetingSessionDetailsResponseType } from '../../../../lib/api';
import './GeneralSessions.scss';
import './SessionMeetingsTitle.scss';
import { MediaQueryContextType, useMediaQuery } from '../../../../lib/context-providers/media-query-provider';
import { MeetingPresentersList } from '../MeetingPresenterList';
import { MeetingTopic } from '../MeetingTopic';
import { FooterButtons } from '../CarouselLayout/FooterButtons';
import { IAuthContext, useAuth } from '../../../../lib/context-providers/auth-context';

interface IProps {
  meeting: PrivateMeetingSessionDetailsResponseType;
  event: EventType;
  isExpandedView: boolean;
}

const SessionMeetingsTile: React.FC<IProps> = (props: IProps): JSX.Element => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;

  const {
    meeting,
    event,
    isExpandedView,
  } = props;

  const classNameSessionTableCellTitle = 'editable-session--table-cell-title';

  const mediaQuery = useMediaQuery() as MediaQueryContextType;
  const isMobile = mediaQuery?.isMobile;

  const featuredMeeting =
    <div
      className='h-full mb-2 mx-2 bg-white sm-shadow-gray flex flex-row justify-between'
      key={meeting.meetingId}
    >
      <div className={`flex justify-between flex-col w-full ${isMobile ? '' : 'p-5'}`}>
        {meeting.thumbnailImageUrl && isMobile &&
          <div className={`${isExpandedView && !isMobile ? 'session-meeting--table-cell-image-expanded px-5 pt-5' : 'session-meeting--table-cell-image-not-expanded '}`}>
            <img src={meeting.thumbnailImageUrl} alt='thumbnail' />
          </div>
        }
        <div className={`${classNameSessionTableCellTitle} session-meeting--table-cell-title-not-expanded flex flex-col justify-between items-start`}>
          <div className='h-full word-break overflow-hidden'>
            <MeetingTopic
              orgs={meeting.presenterOrganizations ?? []}
              topic={meeting.topic}
              isFeatured={meeting.featured}
              isExpandedView={isExpandedView}
              gridView
            />
            <MeetingPresentersList orgs={meeting.presenterOrganizations ?? []} />
          </div>
        </div>
        <div className='mx-10px flex flex-col mb-4' dangerouslySetInnerHTML={{ __html: meeting.notes }}></div>
        {isAuthenticated &&
          <FooterButtons
            meeting={meeting}
            event={event}
            isRegisterBtnShownDown={!!isMobile}
            isExpandedView={isExpandedView}
            isSessionsTab
            withTooltip={!isExpandedView}
            featured={meeting.featured}
          />
        }
      </div>
      {meeting.thumbnailImageUrl && !isMobile &&
        <div className='flex w-1/3 items-start'>
          <img className='object-contain p-2 pl-0' src={meeting.thumbnailImageUrl} alt='thumbnail' />
        </div>}
    </div>;

  const nonFeaturedMeeting =
    <div
      className={`${isExpandedView ? '' : 'h-full '}mb-2 bg-white sm-shadow-gray flex flex-col justify-between`}
      key={meeting.meetingId}
    >
      {meeting.thumbnailImageUrl &&
        <div className={`${isExpandedView && !isMobile ? 'session-meeting--table-cell-image-expanded px-5 pt-5' : 'session-meeting--table-cell-image-not-expanded '}`}>
          <img src={meeting.thumbnailImageUrl} alt='thumbnail' />
        </div>}
      <div
        className={`session-meeting--table-cell-topic ${classNameSessionTableCellTitle} ${isExpandedView ? 'px-5 pb-3' : 'session-meeting--table-cell-title-not-expanded'} flex flex-col justify-between items-start`}
      >
        <div className='h-full word-break overflow-hidden'>
          <MeetingTopic
            orgs={meeting.presenterOrganizations ?? []}
            topic={meeting.topic}
            isFeatured={meeting.featured}
            isExpandedView={isExpandedView}
            gridView
          />
          {isExpandedView && <MeetingPresentersList orgs={meeting.presenterOrganizations ?? []} />}
        </div>
      </div>
      {isAuthenticated &&
        <FooterButtons
          meeting={meeting}
          event={event}
          isRegisterBtnShownDown={isMobile || !isExpandedView}
          withTooltip={!isExpandedView}
          isExpandedView={isExpandedView}
          isSessionsTab
          featured={meeting.featured}
        />
      }
    </div>;

  return meeting.featured ? featuredMeeting : nonFeaturedMeeting;
};

export default SessionMeetingsTile;
