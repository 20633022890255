import { CustomPageProps, SessionLayout } from './custom-page.types';
import * as protectedApi from './protected/index';
import * as publicApi from './public/index';
import { ConnectionData, ConnectionDetailsType } from './connection.types';

/* API KEY */
export const PUBLIC = 'PUBLIC', PROTECTED = 'PROTECTED';

/* URL PATH KEYS */
export const
  EVENT = 'event',
  PRESENTER = 'presenter',
  PARTICIPANT = 'participant',
  MEETING = 'meeting',
  SESSION = 'session',
  REPLAYS = 'replays',
  OPEN_SESSION = 'open-session',
  LIST = 'list',
  ATTENDEE = 'attendee',
  INFO = 'info',
  JOIN_MEETING = 'join',
  RESEND_CODE = 'resend-code',
  ATTEND = 'attend',
  LEAVE = 'leave',
  TRACK = 'track',
  REGISTER = 'register',
  EXPORT='export',
  ICS = 'ics',
  GOOGlE = 'google',
  SPEAKER = 'speaker',
  EVENT_TOKEN = 'event-token',
  PREREGISTER = 'preregister',
  PREREGISTER_TO_WEBINAR = 'preregister-to-webinar',
  REGISTER_TO_WEBINAR = 'register-to-webinar',
  CONFIGURATION = 'configuration',
  PROPERTIES = 'properties',
  REGISTRATION_CONFIGURATION = 'registration-configuration'
;


/* MEETING ATTENDEE */
export type MeetingAttendeeDetailsType = {
  attendeeId: number,
  firstName: string,
  lastName: string,
  title: string,
  profileImageUrl: string,
  biography: string
}
export type MeetingAttendeeDetailsListType = Array<MeetingAttendeeDetailsType>;


/* MEETINGS ORGANIZATION */
export type MeetingOrganizationDetailsType = {
  name: string,
  organizationId: number,
  attendees?: MeetingAttendeeDetailsListType
}
export type MeetingOrganizationDetailsListType = Array<MeetingOrganizationDetailsType>;

export enum FilterReplays {
  UpcomingReplays = 'REPLAYS_UPCOMING',
  PastReplays = 'REPLAYS_PAST',
}

export type LinkType = {
  linkId: number;
  label?: string;
  url: string;
};

/* MEETING DETAILS */
export type MeetingDetailsType = {
  connection: ConnectionData | null;
  startDateTime: string,
  endDateTime: string,
  topic: string,
  type: string,
  access: string,
  track: string,
  notes: string,
  connectionDetails?: string,
  eventId?: string,
  meetingId: string,
  attendeeLink?: string,
  backgroundFile?: string,
  backgroundTitle?: string,
  thumbnailImageUrl?: string,
  featured: boolean,
  heroImageUrl?: string
  presenterPortalLink?: string;
  primaryColor?: string,
  eventName?: string,
  registrationNeeded?: boolean,
  links: LinkType[];
  timeZoneDisplayType: string,
  customTimeZoneDisplay?: string
}

export type PublicMeetingDetailsResponseType = {
  timeZone: string,
  eventEndDateTime: string,
  eventStartDateTime: string,
  eventName: string,
  logoTitle?: string,
  logoUrl?: string,
  publicDescription?: string,
  eventTitleForPassport?: string,
} & MeetingDetailsType;

export type PresenterParticipantDetailsResponseType = {
  backgroundUrl?: string,
  logoUrl?: string,
  primaryColor?: string,
  containsUpcomingOnly?: boolean,
  connectionDetails: ConnectionDetailsType[],
  errorMessage: string,
  eventEndDateTime: string,
  eventName: string,
  eventStartDateTime: string,
  eventTimeZone: string,
  organizationName: string,
  publicDescription?: string,
  eventTitleForPassport?: string,
  timeZoneDisplayType: string,
  customTimeZoneDisplay?: string
};

type PublicMeetingDetailsList = Array<PublicMeetingDetailsResponseType>;

export type PublicSessionMeetingDetails = {
  meetingId: number,
  presenterOrganizations?: MeetingOrganizationDetailsListType
} & Omit<MeetingDetailsType, 'meetingId'>;

export type MeetingDetailsListResponseType = {
  futureMeetings: PublicMeetingDetailsList,
  pastMeetings: PublicMeetingDetailsList
}

export type PrivateMeetingDetailsResponseType = {
  presenterOrganizations?: MeetingOrganizationDetailsListType,
  participantOrganizations?: MeetingOrganizationDetailsListType,
  hostOrganizations?: MeetingOrganizationDetailsListType
} & MeetingDetailsType;



/* MEETING SESSION */
export type PrivateMeetingSessionDetailsResponseType = {
  access: string,
  joinStatus?: string,
} & PrivateMeetingDetailsResponseType;
export type PrivateMeetingSessionDetailsListResponseType = Array<PrivateMeetingSessionDetailsResponseType>;

/* CURRENT USER */
export type CurrentUserResponseType = {
  knovioToken: string,
  email: string,
  firstName: string | null,
  lastName: string | null,
  attendeeId: number,
  title: string | null,
  externalAttendeeId: number | null,
  eventId: number,
  organizationId: number | null,
  externalOrganizationId: number | null,
  organizationName: string | null,
  customFields?: Record<string, string>
};


/* EVENT SETTINGS */

export type FooterLinkType = {
  order: number,
  label: string,
  url: string
}
export type FooterLinkListType = Array<FooterLinkType>;

type EventPublicSettingsType = {
  logoUrl?: string,
  logoTitle?: string,
  backgroundFile?: string,
  backgroundTitle?: string,
  primaryColor?: string,
  loginErrorMessage?: string,
  loginInstructions?: string,
  footerLinks: FooterLinkListType,
  signInPageLabel?: string,
  requestCodeSignInEnabled: boolean,
  buttonAccessCodeRequestEnabled: boolean,
  buttonAccessCodeSignInEnabled: boolean,
}

/* CUSTOM FIELDS */
export type CustomFieldsType = {
  label: string,
  mandatory: boolean,
  positionOrder: number,
  visible?: boolean,
  default?: boolean,
  customFieldId?: number,
  formKey?: string,
  fieldType?: string;
  optionsList?: string[];
  triaged?: boolean;
};

export type RegistrationConfiguration = {
  calendarButtonEnabled: boolean | undefined,
  autoSignInEnabled: boolean | undefined,
  showOpenMeetings: boolean | undefined,
  registrationHeader: string | undefined,
  registrationInstructions: string | undefined,
  registrationSubmittedMessage: string | undefined,
}

export type CustomFieldsResponseType = {
  fields: CustomFieldsType[];
  triagedRegistrationEnabled: boolean;
  configuration: RegistrationConfiguration;
};

/* EVENT DETAILS */
export type EventPublicDetailsResponseType = {
  eventId: number,
  name: string,
  subdomain: string,
  publicDescription: string,
  startTime: string,
  endTime: string,
  timeZone: string,
  customCss: string,
  calendarButtonEnabled: boolean,
  registrationEnabled: boolean,
  signInLandingWith: string,
  allowedProviders: string[],
  registrationAsLandingPage?: boolean,
  eventTitleForPassport?: string,
  accessCodeHeader: string,
  accessCodeInstructions: string,
  accessCodeNavigationButtonText: string,
  accessCodeRequestedMessage: string,
  accessCodeRequestButtonText: string,
  privacyPolicyEnabled: boolean,
  privacyPolicyLabel: string,
  showCookieNotification: boolean,
  cookieNotificationText: string,
  showWebsiteLogoImage: boolean,
  websiteLogoUrl?: string,
  websiteLogoTitle?: string,
  customPages: CustomPageProps[],
  showWebsiteFooterLogoImage: boolean,
  websiteFooterLogoUrl?: string,
  websiteFooterLogoTitle?: string,
  timeZoneDisplayType: string,
  customTimeZoneDisplay?: string,
  meetingLinksLabel?: string,
  signInButtonLabel?: string,
  accessCodeRequestButtonLabel?: string,
} & EventPublicSettingsType;


export type EventPrivateDetailsResponseType = {
  privateDescription?: string,
  layout?: SessionLayout,
  preventJoinEnabled: boolean,
  preventJoinBefore?: number,
} & EventPublicDetailsResponseType;

/* TRACKING DATA EVENT */
export enum EventType { PAGE_VIEW = 'pageView', USER_ACTION = 'userAction' }
export enum EventAction { NAVIGATE = 'navigate', CLICK = 'click' }
export type MetadataType = {
  meetingId?: string,
  from?: string,
  source?: string,
  page?: string,
  href?: string,
  label?: string,
  name?: string,
  container?: string,
  tagName?: string
}
export type TrackingDataEventType = {
  type: EventType,
  action: EventAction,
  generatedDateTime: string,
  label?: string,
  value: string,
  metadata: MetadataType
}

export type GoogleCalendarResponseType = {
  link: string,
}

/* SPEAKERS */

export type SpeakerResponseType = {
  attendeeId: number,
  biography: string | null,
  firstName: string,
  lastName: string,
  organizationId: number,
  profileImageUrl: string | null,
  title: string | null
}
export type SpeakerWithOrganizationName = {
  organizationName: string;
} & SpeakerResponseType;

export type SpeakerResponseTypeListType = Array<SpeakerResponseType>;

export type SpeakerOrganizationResponseType = {
  name: string,
  organizationId: number,
  speakers: SpeakerResponseTypeListType
}

export type SpeakerListResponseType = Array<SpeakerOrganizationResponseType>;
export type ReplaysListResponseType = Array<PrivateMeetingSessionDetailsResponseType>;

/* REGISTRATION SUBSCRIPTION */
export type RegistrationSubscriptionParams = {
  appSyncToken: string,
  registrationId: string
}

export default {
  get [PROTECTED](): typeof protectedApi { return protectedApi; },
  get [PUBLIC](): typeof publicApi { return publicApi; }
};

/* WEBINAR REGISTRATION SUBSCRIPTION */
export type WebinarRegistrationSubscriptionParams = RegistrationSubscriptionParams & {
  eventToken: string,
  registrationRequired: boolean
}

/* APPLICATION SETTINGS */
export type AppSettings = {
  appSyncUrl: string;
  cognitoUrl: string;
  region: string;
  ssoAppUrl: string;
  userPoolClientId: string;
  userPoolId: string;
}

export enum AttendeeProcessingResultType {
  NOT_AN_ATTENDEE = 'NOT_AN_ATTENDEE',
  LOGIN_DETAILS = 'LOGIN_DETAILS',
  JOIN_MEETING_DETAILS = 'JOIN_MEETING_DETAILS'
}

export type AttendeeProcessingUrlResponse = {
  type: AttendeeProcessingResultType;
  url: string;
  registrationId?: string;
}