import { useReducer, useState } from 'react';
import Observable from 'zen-observable';
import { registerWebinarCompleteSubscription } from '../config/appsync';
import { OnWebinarRegisterCompletedSubscription } from '../config/appsync/API';
import { Hub } from 'aws-amplify';
import { AttendeeUrlProcessingPolling } from '../lib/services/polling/attendee-url-processing-polling';

type SubscriptionData = { value: { data: OnWebinarRegisterCompletedSubscription }};

export type WebinarRegistrationSubscriptionState = {
  inProgress: boolean,
  joinUrl: null | string,
  error: null | string
}

export enum ACTION_TYPE {
  INITIALIZED = 'INITIALIZED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

type ACTION =
  | { type: ACTION_TYPE.INITIALIZED }
  | { type: ACTION_TYPE.COMPLETED; payload: string }
  | { type: ACTION_TYPE.FAILED, payload?: string }
  ;

export type RegistrationResponse = { registrationId: string, appSyncToken: string };

export type SubscriptionResult = {
  state: WebinarRegistrationSubscriptionState,
  subscribe(response: RegistrationResponse | void): Promise<void>
}

const _defaults: WebinarRegistrationSubscriptionState = { inProgress: false, error: null, joinUrl: null };

export function reducer(state: WebinarRegistrationSubscriptionState, action: ACTION): WebinarRegistrationSubscriptionState {
  switch (action.type) {
  case ACTION_TYPE.INITIALIZED:
    return { ..._defaults, inProgress: true };
  case ACTION_TYPE.COMPLETED:
    return { ..._defaults, joinUrl: action.payload };
  case ACTION_TYPE.FAILED:
    return { ..._defaults, error: action.payload || null };
  default:
    return _defaults;
  }
}


let timeoutId: number | null = null;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const FIVE_MINUTE_TIMEOUT = 5* 60*1000;
const initialState = { inProgress: false, joinUrl: null, error: null };

export const useWebinarRegistrationSubscription = (): SubscriptionResult => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const [ cancelled, cancel ] = useState<boolean>(false);
  const cancelSubscription = () => cancel(true);

  const initSubscription = (client: {unsubscribe(): void, closed: boolean}) => {
    timeoutId = window.setTimeout(() => {
      if (!client.closed) client.unsubscribe();
      subscriptionFailed();
      cancelSubscription();
    }, FIVE_MINUTE_TIMEOUT);

    dispatch({ type: ACTION_TYPE.INITIALIZED });
  };
  const subscriptionCompleted = (payload: string) => dispatch({ type: ACTION_TYPE.COMPLETED, payload });
  const subscriptionFailed = (payload?: string) => dispatch({ type: ACTION_TYPE.FAILED, payload });

  return {
    state,
    async subscribe(response: RegistrationResponse): Promise<void> {
      return new Promise((resolve, reject) => {
        const params = [response?.registrationId, response?.appSyncToken] as [string, string];

        if (!params.every(Boolean)) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore*/
        const client = (registerWebinarCompleteSubscription(...params) as Observable<SubscriptionData>)
          .subscribe({
            next({ value }: SubscriptionData): void {
              const url = value?.data?.onRegisterToWebinarCompleted?.joinUrl ?? '';
              const error = value?.data?.onRegisterToWebinarCompleted?.error;

              if (!cancelled && !error) {
                subscriptionCompleted(url);
              }

              if (!cancelled && error) {
                subscriptionFailed(error);
                if (timeoutId) window.clearTimeout(timeoutId);
              }

              client.unsubscribe();
            },
            error(): void {
              subscriptionFailed();
              client.unsubscribe();
            },
            complete(): void {
              if (timeoutId) window.clearTimeout(timeoutId);
              if (!client.closed) client.unsubscribe();
            }
          })
       ;

        initSubscription(client);

        const cancelSubscriptionOnConnectionFailure = (): void => {
          if (!client.closed) client.unsubscribe();
          if (timeoutId) window.clearTimeout(timeoutId);
          subscriptionFailed();
          cancelSubscription();
        };

        /* Resolve when the subscription connection is established or reject if client fails to connect */
        Hub.listen('api', ({ payload }) => {
          const { event, data } = payload;

          if (event === 'Subscription ack') resolve();

          if (data.connectionState === 'ConnectionDisrupted') {

            /* stop subscription client */
            if (!client.closed) client.unsubscribe();
            if (timeoutId) window.clearTimeout(timeoutId);

            const polling = new AttendeeUrlProcessingPolling(
              response?.registrationId,
              response?.appSyncToken,
              { pollingCancellationTimeout: FIVE_MINUTE_TIMEOUT }
            );

            polling.start()
              .then(response => {
                subscriptionCompleted(response.url);
              })
              .catch(() => {
                cancelSubscriptionOnConnectionFailure();
                reject(new Error('ConnectionDisrupted'));
              });

            polling.subscribeToPollingStart(resolve);
          }
        });
      });

    }
  };
};
