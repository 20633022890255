import React from 'react';
import { JoinUrlContext } from '../../../shared/MeetingDetails';
import ConnectToMeeting, { ZOOM_REGISTRATION_IN_PROGRESS_TEXT } from '../ConnectToMeeting';
import OpenPresenterLink from '../OpenPresenterLink';
import DefaultSpinner from '../../../shared/DefaultSpinner';
import { PrivateMeetingDetailsResponseType } from '../../../../lib/api';
import { Connection } from '../../../../lib/connection/connection';
import { WebinarRegistrationSubscriptionState } from '../../../../hooks/use-webinar-registration-subscription';
import { JoinMethod } from '../../../../lib/join-method/join-method';

import './ConnectionWrapper.scss';

export type ConnectionWrapperProps = {
  meetingDetails: PrivateMeetingDetailsResponseType;
  isConnectBtnDisabled: boolean;
  state: WebinarRegistrationSubscriptionState;
}

const ConnectionWrapper: React.FunctionComponent<ConnectionWrapperProps> = ({ meetingDetails, isConnectBtnDisabled, state }) => {
  const connection = Connection.create(meetingDetails);
  const connectionArray = connection?.getJoinMethods();

  const renderConnectionWrapperContent = (joinMethod: JoinMethod | null, index?: number) => {
    const connectionDetails = !meetingDetails.registrationNeeded && (joinMethod ? joinMethod.meetingInvitation : meetingDetails.connectionDetails);
    const isKnovioConnectionPublish = joinMethod?.isKnovioConnectionType() ? joinMethod.isKnovioAvailable : true;
    const isMultipleConnection = connection?.isMultipleConnection() as boolean;

    if (!isKnovioConnectionPublish) {
      return (
        <div key={index} className='m-8' data-test-id='placeholder-text'>
          <div dangerouslySetInnerHTML={{ __html: joinMethod?.detailsPlaceholderText ?? '' }} />
        </div>
      );
    }

    if (state.inProgress && meetingDetails.registrationNeeded) {
      return (
        <div key={index} className='bg-white m-8 p-8 shadow-gray'>
          <DefaultSpinner visible={true} />
          <div className='pt-4 whitespace-pre-wrap leading-loose'>{ZOOM_REGISTRATION_IN_PROGRESS_TEXT}</div>
        </div>
      );
    }

    return (
      <div key={index}>
        <div className='text-left p-8'>
          <JoinUrlContext.Provider value={state.joinUrl}>
            <ConnectToMeeting {...{ ...meetingDetails, isConnectBtnDisabled, joinMethod, isMultipleConnection }} />
            <OpenPresenterLink presenterPortalLink={meetingDetails.presenterPortalLink} />
          </JoinUrlContext.Provider>
        </div>
        {connectionDetails && !isConnectBtnDisabled && (
          <div className='px-8 font-size-14px'>
            <div className='connection-details-title font-semibold'>Connection Details</div>
            <div className='pt-4 whitespace-pre-wrap leading-loose'>
              {connectionDetails}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='flex-equal sm:flex-1 md:w-1/2 overflow-visible bg-cool-gray'>
      {(!connection || !connectionArray)
        ? renderConnectionWrapperContent(null)
        : connectionArray.map((joinMethod, index) => renderConnectionWrapperContent(joinMethod, index))
      }
    </div>
  );
};

export default ConnectionWrapper;
