import React from 'react';
import { NavLink } from 'react-router-dom';
import { MIN_DESKTOP_LABEL_LENGTH } from '../../../hooks/use-navbar-items';
import { useUserActionTracking, UserActionTrackingProps } from '../../../lib/tracking-data/track-user-action';

export interface IProps extends Partial<NavLink>, Partial<UserActionTrackingProps> {
  className?: string,
  activeClassName?: string,
  onClick?(): void,
  label: string,
  to: string,
  reload?: boolean,
  isMobile: boolean,
  exact?: boolean,
}

const className = 'my-0 px-4 sm:px-4 whitespace-no-wrap font-size-14px font-medium cursor-pointer flex justify-end navbar-item-label';
const activeClassName = 'navbar-item-label-active';

export const NavItem: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const { label, isMobile, reload, onClick, ...rest } = props;

  const trackingProps = useUserActionTracking();

  const style = { minWidth: '10ch' };
  const _props = label.length > MIN_DESKTOP_LABEL_LENGTH ? { ...rest, style } : rest;

  const reloadPage = () => {
    if (reload) {
      const { pathname, origin } = window.location;
      /* [OEC-6073] reloading the page only if the user is already on that page */
      if (pathname === props.to) window.location.assign(origin + props.to);
      if (isMobile && onClick) onClick();
    }
  };

  const navLabel = isMobile
    ? <span className='truncate leading-4 my-4'>{label}</span>
    : <span className='truncate w-full'>{label}</span>
  ;

  return (
    <NavLink {..._props} {...trackingProps} onClick={reload ? reloadPage : (isMobile ? onClick : undefined)}>
      {navLabel}
    </NavLink>
  );
};

NavItem.defaultProps = {
  className,
  activeClassName
};
