export function truncateString(str: string, num: number): string {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function getPathName(path: string): string {
  return path.replace("/", "");
}

export function parsePhoneNumbers(numbers: string | null, participantOneTapDial: string | null): string[] {
  const numbersArray = numbers?.trim().split("\n");

  if (numbersArray && participantOneTapDial) {
    numbersArray.unshift(participantOneTapDial);
    return numbersArray;
  } else if (numbersArray) {
    return numbersArray;
  }
  return [];
}