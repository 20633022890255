import React, { useState } from 'react';
import DialInSelect from '../../../components/_base/DialInSelect';
import { parsePhoneNumbers } from '../../../lib/helpers/text-formatters';
import Icon from '../../../components/_base/Icon';
import { JoinMethod } from '../../../lib/join-method/join-method';

import './DialInInfo.scss';

export type DialInInfoProps = {
  joinMethod: JoinMethod;
}

const DialInInfo: React.FunctionComponent<DialInInfoProps> = (props: DialInInfoProps): JSX.Element => {
  const {
    joinMethod
  } = props;

  const countries = joinMethod.getCountries();
  const parsedPhoneNumbers = parsePhoneNumbers(joinMethod.participantTelephoneNumbers, joinMethod.participantOneTapDial);

  const countryInitial = countries ? countries[0] : '';
  const phoneNumbersInitial = joinMethod.transformedAnsSortedNumbersSet
    ? joinMethod.getPhoneNumbersByCountry(countryInitial)
    : parsedPhoneNumbers;

  const isOneTapFormulaSpecified = joinMethod.conferenceId && joinMethod.participantId && joinMethod.connectionPassword;
  const oneTapFormulaBase = isOneTapFormulaSpecified
    ? `,,${joinMethod.conferenceId}#,,,,${joinMethod.participantId}#,,,,${joinMethod.connectionPassword}#`
    : '';
  const tel = `tel:${phoneNumbersInitial[0]}`;
  const oneTapFormulaInitial = isOneTapFormulaSpecified ? `${tel}${oneTapFormulaBase}` : tel;

  const [country, setCountry] = useState<string>(countryInitial);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(phoneNumbersInitial);
  const [phoneNumber, setPhoneNumber] = useState<string>(phoneNumbersInitial[0]);
  const [oneTapFormula, setOneTapFormula] = useState<string>(oneTapFormulaInitial);

  const handleCountryChange = (value: string): void => {
    const phoneNumbersByCounty = joinMethod.getPhoneNumbersByCountry(value) ?? [];
    setCountry(value);
    setPhoneNumbers(phoneNumbersByCounty);
    setPhoneNumber(phoneNumbersByCounty[0]);
    setOneTapFormula(`tel:${phoneNumbersByCounty[0]}${oneTapFormulaBase}`);
  };

  const selectTelephoneNumber = (value: string): void => {
    setPhoneNumber(value);
    setOneTapFormula(`tel:${value}${oneTapFormulaBase}`);
  };

  return (
    <div className='dial-in-info-wrapper'>
      {parsedPhoneNumbers &&
        <div className='dial-in-info__phone-number'>
          <span className='dial-in-info__phone-icon-wrapper'>
            <a
              className={`${!phoneNumbersInitial[0].length ? 'pointer-events' : ''} no-underline`}
              href={oneTapFormula}
              data-test-id='dial-in-link'
            >
              <Icon.PhoneIcon
                className='dial-in-info__phone-icon transform rotate-5'
                width='18px'
                height='18px'
              />
            </a>
          </span>
          <DialInSelect
            value={phoneNumber}
            onChange={selectTelephoneNumber}
            options={phoneNumbers}
          />
        </div>
      }
      {joinMethod.conferenceId &&
        <div className='dial-in-info__field'>
          <label className='dial-in-info__label'>Conference ID</label>
          <span className='dial-in-info__value'>{joinMethod.conferenceId}</span>
        </div>
      }
      {joinMethod.participantId &&
        <div className='dial-in-info__field'>
          <label className='dial-in-info__label'>Participant ID</label>
          <span className='dial-in-info__value'>{joinMethod.participantId}</span>
        </div>
      }
      {joinMethod.connectionPassword &&
        <div className='dial-in-info__field'>
          <label className='dial-in-info__label'>Passcode</label>
          <span className='dial-in-info__value'>{joinMethod.connectionPassword}</span>
        </div>
      }
      {joinMethod.transformedAnsSortedNumbersSet && countries &&
        <div className='dial-in-info__field'>
          <label className='dial-in-info__label'>Country</label>
          <DialInSelect
            isCountryDropdown={true}
            value={country}
            onChange={handleCountryChange}
            options={countries}
          />
        </div>
      }
    </div>
  );
};

export default DialInInfo;
